import styled from "styled-components";
import Layout from "../components/Layout";
import Container from "../components/Container";
import banner from "../images/pages/Creator/main_banner.png";
import banner_m from "../images/pages/Creator/main_banner.png";
import request from "../images/pages/Creator/creator2025033101.png";
import request_m from "../images/pages/Creator/creator2025033101.png";
import '../css/privacy.css';

import { useMediaQuery } from "react-responsive";
import Hashtag from "../components/Hashtag";

const ButtonWrap = styled.div`
  
`;
const MobileWrap = styled.div`
  
`;
export default function Creator() {
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });

  return (
    <Layout>
      <Hashtag />
      {!tablet ? (
        <Container className="app">
          <div class="content">
            <h1 class="m-h1">KPUB 개인정보 처리방침</h1>
              <p class="text-container">
                <span class="left-text">KPUB은 이용자 개인정보 보호를 최우선으로 하고 있습니다.</span>
                <span class="right-text" styl>시행일자 : 2025년 3월 10일</span>
              </p>
            <hr/>
              <p class="p-text">
                개인정보보호법에 따라 KPUB에 회원가입 신청하시는 분께 수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의 보유 및 이용기간, 동의 거부권 및 동의 거부 시 불이익에 관한 사항을 안내 드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.
                <br/><br/>
                <b class="b-text">1. 수집하는 개인정보</b>
                <br/><br/>
                이용자는 회원가입을 하지 않아도 콘텐츠를 열람할 수 있습니다. 이용자가 저작도구 사용, 댓글, 리스트 등과 같이 개인화 혹은 회원제 서비스를 이용하기 위해 회원가입을 할 경우, 토브소프트는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.
                <br/><br/>회원가입 시점에 토브소프트가 이용자로부터 수집하는 개인정보는 아래와 같습니다.
                <br/>
                - 회원 가입 시 필수항목으로 이메일, 닉네임, 이름, 전화번호, 직업을 수집합니다.
                <br/>
                단, 간편 로그인을 통해 회원 가입 시에는 필수항목으로 이메일, 이름, 휴대전화번호를 수집합니다.
                <br/><br/>

                서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.<br/>
                - 회원정보 또는 개별 서비스에서 프로필 정보(별명, 프로필 사진)를 설정할 수 있습니다.<br/>
                서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보, 위치정보가 생성되어 수집될 수 있습니다.<br/><br/>
                구체적으로 <br/>
                1) 서비스 이용 과정에서 이용자에 관한 정보를 자동화된 방법으로 생성하여 이를 저장(수집)하거나,<br/>
                2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못 하도록 안전하게 변환하여 수집합니다. 서비스 이용 과정에서 위치정보가 수집될 수 있습니다.<br/>
                이와 같이 수집된 정보는 개인정보와의 연계 여부 등에 따라 개인정보에 해당할 수 있고, 개인정보에 해당하지 않을 수도 있습니다.


                <br/><br/><br/>
                <b class="b-text">2. 수집한 개인정보의 이용</b>
                <br/><br/>
                KPUB 서비스의 회원관리, 서비스 개발・제공 및 향상, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다.<br/>
                - 회원 가입 의사의 확인, 이용자의 본인 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를 위하여 개인정보를 이용합니다.<br/>
                - 콘텐츠 등 기존 서비스 제공(광고 포함)에 더하여, 인구통계학적 분석, 서비스 방문 및 이용기록의 분석, 개인정보 및 관심에 기반한 이용자간 관계의 형성 등 신규 서비스 요소의 발굴 및 기존 서비스 개선 등을 위하여 개인정보를 이용합니다.<br/>
                - 법령 및 KPUB 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.<br/>
                - 유료 서비스 제공에 따르는 본인인증, 구매 및 요금 결제를 위하여 개인정보를 이용합니다.<br/>
                - 이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션 목적으로 개인정보를 이용합니다.<br/>
                - 서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.<br/>
                - 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을 위해 개인정보를 이용합니다.
                
                
                <br/><br/><br/>
                <b class="b-text">3. 개인정보의 보관기간</b>
                <br/><br/>
                회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기하고 있습니다.<br/>
                단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.<br/>
                이용자에게 개인정보 보관기간에 대해 회원가입 시 또는 서비스 가입 시 동의를 얻은 경우는 아래와 같습니다.<br/>
                - 부정 가입 및 이용 방지<br/>
                부정 이용자의 가입인증 휴대전화번호 : 탈퇴일로부터 6개월 보관<br/>
                탈퇴한 이용자의 휴대전화번호(복호화가 불가능한 일방향 암호화(해시처리)) : 탈퇴일로부터 6개월 보관<br/>
                - QR코드 복구 요청 대응<br/>
                QR코드 등록 정보:삭제 시점으로부터 6개월 보관<br/>
                전자상거래 등에서의 소비자 보호에 관한 법률, 전자문서 및 전자거래 기본법, 통신비밀보호법 등 법령에서 일정기간 정보의 보관을 규정하는 경우는 아래와 같습니다. 
                <br/>
                토브소프트는 이 기간 동안 법령의 규정에 따라 개인정보를 보관하며, 본 정보를 다른 목적으로는 절대 이용하지 않습니다.<br/>
                - 전자상거래 등에서 소비자 보호에 관한 법률<br/>
                계약 또는 청약철회 등에 관한 기록: 5년 보관<br/>
                대금결제 및 재화 등의 공급에 관한 기록: 5년 보관<br/>
                소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관<br/>
                - 전자문서 및 전자거래 기본법<br/>
                로그인 기록: 3개월<br/>
                참고로 토브소프트는 ‘개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다.


                <br/><br/><br/>
                <b class="b-text">4. 개인정보 수집 및 이용 동의를 거부할 권리</b>
                <br/><br/>
                이용자는 개인정보의 수집 및 이용 동의를 거부할 권리가 있습니다. 회원가입 시 수집하는 최소한의 개인정보, 즉, 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회원가입이 어려울 수 있습니다.<br/>
                
              </p>

          </div>
        </Container>
      ) : (
        <MobileWrap>
          <div class="content">
            <h1 class="m-h1">KPUB 개인정보 처리방침</h1>
              <p class="text-container">
                <span class="left-text">KPUB은 이용자 개인정보 보호를 최우선으로 하고 있습니다.</span>
                <span class="right-text" styl>시행일자 : 2025년 3월 10일</span>
              </p>
            <hr/>
              <p class="p-text">
                개인정보보호법에 따라 KPUB에 회원가입 신청하시는 분께 수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의 보유 및 이용기간, 동의 거부권 및 동의 거부 시 불이익에 관한 사항을 안내 드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.
                <br/><br/>
                <b class="b-text">1. 수집하는 개인정보</b>
                <br/><br/>
                이용자는 회원가입을 하지 않아도 콘텐츠를 열람할 수 있습니다. 이용자가 저작도구 사용, 댓글, 리스트 등과 같이 개인화 혹은 회원제 서비스를 이용하기 위해 회원가입을 할 경우, 토브소프트는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.
                <br/><br/>회원가입 시점에 토브소프트가 이용자로부터 수집하는 개인정보는 아래와 같습니다.
                <br/>
                - 회원 가입 시 필수항목으로 이메일, 닉네임, 이름, 전화번호, 직업을 수집합니다.
                <br/>
                단, 간편 로그인을 통해 회원 가입 시에는 필수항목으로 이메일, 이름, 휴대전화번호를 수집합니다.
                <br/><br/>

                서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.<br/>
                - 회원정보 또는 개별 서비스에서 프로필 정보(별명, 프로필 사진)를 설정할 수 있습니다.<br/>
                서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보, 위치정보가 생성되어 수집될 수 있습니다.<br/><br/>
                구체적으로 <br/>
                1) 서비스 이용 과정에서 이용자에 관한 정보를 자동화된 방법으로 생성하여 이를 저장(수집)하거나,<br/>
                2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못 하도록 안전하게 변환하여 수집합니다. 서비스 이용 과정에서 위치정보가 수집될 수 있습니다.<br/>
                이와 같이 수집된 정보는 개인정보와의 연계 여부 등에 따라 개인정보에 해당할 수 있고, 개인정보에 해당하지 않을 수도 있습니다.


                <br/><br/><br/>
                <b class="b-text">2. 수집한 개인정보의 이용</b>
                <br/><br/>
                KPUB 서비스의 회원관리, 서비스 개발・제공 및 향상, 안전한 인터넷 이용환경 구축 등 아래의 목적으로만 개인정보를 이용합니다.<br/>
                - 회원 가입 의사의 확인, 이용자의 본인 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를 위하여 개인정보를 이용합니다.<br/>
                - 콘텐츠 등 기존 서비스 제공(광고 포함)에 더하여, 인구통계학적 분석, 서비스 방문 및 이용기록의 분석, 개인정보 및 관심에 기반한 이용자간 관계의 형성 등 신규 서비스 요소의 발굴 및 기존 서비스 개선 등을 위하여 개인정보를 이용합니다.<br/>
                - 법령 및 KPUB 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.<br/>
                - 유료 서비스 제공에 따르는 본인인증, 구매 및 요금 결제를 위하여 개인정보를 이용합니다.<br/>
                - 이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션 목적으로 개인정보를 이용합니다.<br/>
                - 서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.<br/>
                - 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을 위해 개인정보를 이용합니다.
                
                
                <br/><br/><br/>
                <b class="b-text">3. 개인정보의 보관기간</b>
                <br/><br/>
                회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기하고 있습니다.<br/>
                단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.<br/>
                이용자에게 개인정보 보관기간에 대해 회원가입 시 또는 서비스 가입 시 동의를 얻은 경우는 아래와 같습니다.<br/>
                - 부정 가입 및 이용 방지<br/>
                부정 이용자의 가입인증 휴대전화번호 : 탈퇴일로부터 6개월 보관<br/>
                탈퇴한 이용자의 휴대전화번호(복호화가 불가능한 일방향 암호화(해시처리)) : 탈퇴일로부터 6개월 보관<br/>
                - QR코드 복구 요청 대응<br/>
                QR코드 등록 정보:삭제 시점으로부터 6개월 보관<br/>
                전자상거래 등에서의 소비자 보호에 관한 법률, 전자문서 및 전자거래 기본법, 통신비밀보호법 등 법령에서 일정기간 정보의 보관을 규정하는 경우는 아래와 같습니다. 
                <br/>
                토브소프트는 이 기간 동안 법령의 규정에 따라 개인정보를 보관하며, 본 정보를 다른 목적으로는 절대 이용하지 않습니다.<br/>
                - 전자상거래 등에서 소비자 보호에 관한 법률<br/>
                계약 또는 청약철회 등에 관한 기록: 5년 보관<br/>
                대금결제 및 재화 등의 공급에 관한 기록: 5년 보관<br/>
                소비자의 불만 또는 분쟁처리에 관한 기록: 3년 보관<br/>
                - 전자문서 및 전자거래 기본법<br/>
                로그인 기록: 3개월<br/>
                참고로 토브소프트는 ‘개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다.


                <br/><br/><br/>
                <b class="b-text">4. 개인정보 수집 및 이용 동의를 거부할 권리</b>
                <br/><br/>
                이용자는 개인정보의 수집 및 이용 동의를 거부할 권리가 있습니다. 회원가입 시 수집하는 최소한의 개인정보, 즉, 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회원가입이 어려울 수 있습니다.<br/>
                
              </p>

          </div>
        </MobileWrap>
      )}
    </Layout>
  );
}
