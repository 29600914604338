import React, { useRef, useState, useEffect } from 'react';
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";
import ThumbnailLine5, { ThumbnailDummy } from "./ThumbnailLine5";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Grid, Pagination, Navigation, Scrollbar, Mousewheel, Keyboard } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
};

const Wrap = styled.div`
  position: relative;
  display: grid;
  
  // grid-template-columns: repeat(2, minmax(330px, 400px));
  // grid-template-rows: repeat (2, minmax(187px, auto))
  grid-template-columns: repeat(${(props) => (props.myRoom ? 4 : 2)}, 431px);
  grid-template-rows: repeat(${(props) => (props.myRoom ? 1 : 2)}, auto);

  height: ${(props) => (props.row ? "fit-content" : "fit-content")};

  overflow: hidden;
  width: ${(props) => (props.myRoom ? "892px" : "100%")};
  gap: ${(props) => (props.myRoom ? "25px" : "14px")};
  margin: 0 auto;
  margin-bottom: ${(props) => (props.row ? "0" : "40px")};
  > div {
      width: 100%;
  //  width: 212px;
  }
    
  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: repeat(1, 100%);
    height: fit-content;
    width: 100%;
    > div {
      width: 100%;
    }
  }
 `;

export default function ThumbGridLine5({ data, row, lastElementRef, myRoom, title }) {

  const { height, width } = getWindowDimensions();
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });
  return (
    <Wrap row={row} myRoom={myRoom}>
      {tablet && row
        ? data.slice(0, 6).map((content, i) => (
            <div key={i} ref={data.length - 1 === i ? lastElementRef : undefined}>
              <ThumbnailLine5
                bgurl={content.coverUrl}
                title={content.bookTitle}
                publisher={content.nickName}
                view={content.viewCnt}
                id={content.eSeq}
                favor={content.favorCnt}
                bookUrl={content.bookUrl}
              ></ThumbnailLine5>
            </div>
          )) 
        : data.map((content, i) => (
            <div key={i} ref={data.length - 1 === i ? lastElementRef : undefined}>
              <ThumbnailLine5
                bgurl={content.coverUrl}
                title={content.bookTitle}
                publisher={i+content.nickName}
                view={content.viewCnt}
                id={content.eSeq}
                favor={content.favorCnt}
                bookUrl={content.bookUrl}
              ></ThumbnailLine5>
            </div>
          ))}
    </Wrap>
  );
}
