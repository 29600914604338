import { useQuery } from "@tanstack/react-query";
import { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import EbookList from "../components/EbookList";
import EbookListLine1 from "../components/EbookListLine1";
import EbookListLine2 from "../components/EbookListLine2";
import EbookListLine3 from "../components/EbookListLine3";
import EbookListLine4 from "../components/EbookListLine4";
import EbookListLine5 from "../components/EbookListLine5";
import EbookListRecentUpload from "../components/EbookListRecentUpload";

// import BannerSlider from "../components/BannerSlider";
import BannerContainer from "../components/BannerContainer";
import IconContainer from "../components/IconContainer";
import Layout from "../components/Layout";
import Container from "../components/Container";
import NewContainer from "../components/NewContainer";
import Hashtag from "../components/Hashtag";
import AD from "../components/AD";
import { getPopularList, getRecentUploadList, getSuggestionList, getPopupInfo, getSelCategoryContentsList } from "../core/api";
import Loading from "../components/Loading";
import AD_1 from "../images/components/AD/AD_1.svg";
import AD_2 from "../images/components/AD/AD_2.svg";
import AD_1_m from "../images/components/AD/AD_1_m.svg";
import AD_2_m from "../images/components/AD/AD_2_m.svg";
import PopupModal from "../components/PopupModal";

import BottomBannerImage from "../images/main_banner.png"; 

const Greeting = styled.section`
  width: 100%;
  height: 240px;
  aspect-ratio: 1200/624;
  margin: 60px auto 0px;
  box-sizing: border-box;

  display: column;
  text-align: center; 

  div.intro{
    margin-bottom: 6px;
    font-family: "Pretendard-Light";
    font-size: 48px;
    line-height: 60px;
    color: #303441;
    text-align: center;    
    @media ${({ theme }) => theme.device.tablet} {
      font-size: 4vh;
      line-height: 5vh;
    }
  }
  
  div.btnbox{
    width: 140px;
    height: 40px;
    
    display: inline-block;
    line-height: 40px;
    text-align: center;
  }

  #btnbox1{
    color: white;
    border: 1px solid red;
    background-color: red;
    border-radius: 10px;
  }
  
  #btnbox2{
    color: red;
    background-color: white;
    border: 1px solid red;
    border-radius: 10px;
  }

  #btnbox3{
    width: 15px;
    height: 40px;
  }
  
  @media ${({ theme }) => theme.device.tablet} {
    min-width: 100%;
    width: 100%;
    margin: 10px auto 0px;
    height: 180px;
  }
`;

const Banner = styled.section`
  width: 100%;
  height: 624px;
  aspect-ratio: 1200/624;
  margin: 0px auto 0;
  box-sizing: border-box;
  @media ${({ theme }) => theme.device.tablet} {
    min-width: 100%;
    width: 100%;
    height: auto;
    margin: 0;
  }
`;

const IconList = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
  margin: 40px auto;

  @media ${({ theme }) => theme.device.tablet} {
  min-width: 100%;
  width: 100%;
  height: auto;
  }
`;

const BottomAD = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 80px;
  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 72px;
  }
`;

const BottomBanner = styled.section`
  position: absolute;
  display: inline-block;
  width: 48.8%;
  @media ${({ theme }) => theme.device.tablet} {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
  }
`;

export default function Home() {
  // 팝업공지 노출여부
  const [modalVisible, setModalVisible] = useState(false);
  const {data: popup, isLoading: isLoadingPopup } = useQuery(["popup"], getPopupInfo);
  const [popups, setPopups] = useState([]);

  useLayoutEffect(() => {
    if (popup?.rows.length>0) {
      setPopups((old) => [...popup?.rows]);
      setModalVisible(true);
      lockScroll();
    }
  }, [popup]);

  // E-book List
  const { data: suggestion, isLoading: isLoadingSuggestion } = useQuery(["suggestion"], getSuggestionList);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (suggestion?.rows) {
      setSuggestions((old) => [...suggestion?.rows]);
    }
  }, [suggestion]);
  
  const { data: recentUpLoad, isLoading } = useQuery(["recentUpLoad"], getRecentUploadList);
  const [recentUpLoads, setRecentUpLoads] = useState([]);

  useEffect(() => {
    if (recentUpLoad?.rows) {
      setRecentUpLoads((old) => [...recentUpLoad?.rows]);
    }
  }, [recentUpLoad]);

  const { data: popular, isLoading: isLoadingPopular } = useQuery(["popular"], getPopularList);
  const [popularContents, setPopularContents] = useState([]);

  useEffect(() => {
    if (popular?.rows) {
      setPopularContents((old) => [...popular?.rows]);
    }
  }, [popular]);

  /***********************************************************
   * 2024-12-10 리호 
   * 카테고리 콘텐츠 리스트 쿼리 추가 
   * ## 아래 m001~m006을 cType 파라메터값으로 사용.
   *     m001 [메인] 1번 라인 종썸네일 6개
   *     m002 [메인] 2번 라인 횡썸네일 3개
   *     m003 [메인] MD's PICK
   *     m004 [메인] 4번 라인 횡썸네일 5개
   *     m005 [메인] 5번 라인 횡썸네일 4개
   *     m006 [메인] 5번 라인 큰배너 1개 --> 수동기입할 예정이므로 그냥 이미지 배너만 그대로 넣어주세요.
   * cType 파라메터값 예시 : m001, m002, m003, m004, m005, m006(제외)
   * 총 5개 카테고리 콘텐츠 리스트 쿼리 추가 (아래 코드와 배너 제외해서 4개 더 추가 필요)
   */

  const { data: categoryContents1, isLoading: isLoadingCategory1 } = useQuery(
    ["categoryContents1", { "cType": "m001" }],
    () => getSelCategoryContentsList({ "cType": "m001" })
  );
  const [categoryList1, setCategoryList1] = useState([]);

  useEffect(() => {
    if (categoryContents1?.rows) {
      setCategoryList1(categoryContents1.rows);
    }
  }, [categoryContents1]);

  const { data: categoryContents2, isLoading: isLoadingCategory2 } = useQuery(
    ["categoryContents2", { "cType": "m002" }],
    () => getSelCategoryContentsList({ "cType": "m002" })
  );
  const [categoryList2, setCategoryList2] = useState([]);
  
  useEffect(() => {
    if (categoryContents2?.rows) {
      setCategoryList2(categoryContents2.rows);
    }
  }, [categoryContents2]);

  const { data: categoryContents3, isLoading: isLoadingCategory3 } = useQuery(
    ["categoryContents3", { cType: "m003" }],
    () => getSelCategoryContentsList({ "cType": "m003" })
  );
  const [categoryList3, setCategoryList3] = useState([]);
  
  useEffect(() => {
    if (categoryContents3?.rows) {
      setCategoryList3(categoryContents3.rows);
    }
  }, [categoryContents3]);

  const { data: categoryContents4, isLoading: isLoadingCategory4 } = useQuery(
    ["categoryContents4", { cType: "m004" }],
    () => getSelCategoryContentsList({ "cType": "m004"})
  );  
  const [categoryList4, setCategoryList4] = useState([]);
  
  useEffect(() => {
    if (categoryContents4?.rows) {
      setCategoryList4(categoryContents4.rows);
    }
  }, [categoryContents4]);

  const { data: categoryContents5, isLoading: isLoadingCategory5 } = useQuery(
    ["categoryContents5", { cType: "m005" }],
    () => getSelCategoryContentsList({ "cType": "m005"})
  );
  const [categoryList5, setCategoryList5] = useState([]);
  
  useEffect(() => {
    if (categoryContents5?.rows) {
      setCategoryList5(categoryContents5.rows);
    }
  }, [categoryContents5]);  
  
  /***********************************************************/

  /*/////////////////////////////////////////////////////
  //2023-09-06 공지팝업 작업
  // START ////////////////////////////////////////////*/
  // const openModal = () => {
  //     setModalVisible(true)
  // }
  
  const closeModal = () => {
      setModalVisible(false)
      openScroll();
      
  }
  
  const lockScroll = () => {
    document.getElementsByTagName("body")[0].style.overflow = 'hidden';
  }

  const openScroll = () => {
    document.getElementsByTagName("body")[0].style.overflow = 'auto';
  }

  /*/ END ////////////////////////////////////////////////
  //2023-09-06 공지팝업 작업
  //////////////////////////////////////////////////////*/

  /*///////////////////////////////////////////////////////////////////////////////
  // 2025-01-09 접속하는 기기가 세로형 모바일 디바이스인 경우,
  // - 운영체계가 아이폰 또는 안드로이드이고 너비가 479 이하의 디바이스 인 경우 
  // - 제목 부분을 보이지 않도록 처리 (기존 배너와 아이콘 리스트를 호출하지 않도록 함)
  // - 기존 이북리스트 중 발견과 인기 컨텐츠를 보이지 않도록 처리
  // - 카테고리 별 컨텐츠를 각각의 요구사항에 맞춰 종, 횡 또는 종/횡으로 디스플레이 (이북리스트1~5)
  // - 기존 하단 광고 보이지 않도록 처리
  // - 최신 컨텐츠의 경우 무한 스크롤로 베공하고 푸터 보이지 않도록 처리 (Layout 수정)
  ////////////////////////////////////////////////////////////////////////////////*/

    const user = navigator.userAgent;
    const windowInnerWidth = window.innerWidth;
  
  if ((((user.indexOf("iPhone") > -1 || user.indexOf("Android")) > -1)) && (windowInnerWidth < 480)) {
    return (
      <Layout>
      {isLoadingCategory1 ||
       isLoadingCategory2 ||
       isLoadingCategory3 ||
       isLoadingCategory4 ||
       isLoadingCategory5 ||
       isLoading
        ? <Loading /> : null}
      <Hashtag />

{/* 배너 부분 보이지 않도록 처리
        <Banner>
          <BannerContainer />
        </Banner>
*/}
        <Greeting>
          <div className="intro">
            나 만의 책을 <br/> 친구들과 공유 하세요
          </div>
          <div id = "btnbox1" className = "btnbox"> 
            <Link to="./contentGuide" className="btnbox"> 바로 시작하기 </Link>
          </div>
          <div id = "btnbox3" className="btnbox"> 
          </div>
          <div id = "btnbox2" className="btnbox"> 
            <Link to="./template" className="btnbox"> 템플릿 보러가기 </Link>
          </div>
        </Greeting>
        <Container>

        <IconList>
          <IconContainer />
        </IconList>

        <EbookListLine1
              title={"카테고리 컨텐츠 1"}
              description={"1번 라인 종썸네일 6개"}
              data={categoryList1}
              name={"categoryContents1"}
              needFilter={false}
              linkTo={"/moreEbook"}
              moreContents={true}
          />
          <EbookListLine2
              title={"카테고리 컨텐츠 2"}
              description={"2번 라인 횡썸네일 4개"}
              data={categoryList2}
              name={"categoryContents2"}
              needFilter={false}
              linkTo={"/moreEbook"}
              moreContents={true}
          />
          <EbookListLine3
              title={"MD's Pick"}
              description={"MD's Pick 6개"}
              data={categoryList3}
              name={"categoryContents3"}
              needFilter={false}
              linkTo={"/moreEbook"}
              moreContents={true}
          />
          <EbookListLine4
              title={"테스트4"}
                description={"4번 라인 횡썸네일 4개"}
              data={categoryList4}
              name={"categoryContents4"}
              needFilter={false}
              linkTo={"/moreEbook"}
              moreContents={true}
          />
          <EbookListLine5
              title={"테스트5"}
              description={"5번 라인 횡썸네일 4개"}
              data={categoryList5}
              name={"categoryContents5"}
              needFilter={false}
              linkTo={"/moreEbook"}
              moreContents={true}
          />
          <BottomBanner>
            <Link to={"/Creator"}>
              <img src = {BottomBannerImage}/>
            </Link>
          </BottomBanner>
          <EbookListRecentUpload
            title={"최신 콘텐츠"}
            description={"따끈따끈 새로 올라온 콘텐츠를 만나보세요."}
            data={recentUpLoads}
            name={"recentUpLoad"}
            needFilter={false}
            linkTo={"/contents"}
            options={{ ordering: 0 }}
            moreContents={true}
          />

{/* 기존 이북리스트 보이지 않도록 처리
          <EbookList
            title={"발견!"}
            description={"KPUB이 재미있는 콘텐츠를 발견했어요."}
            data={suggestions}
            name={"suggestion"}
            needFilter={false}
            linkTo={"/moreEbook"}
            moreContents={true}
          />
          <EbookList
            title={"인기 콘텐츠"}
            description={"인기 있는 콘텐츠를 만나보세요."}
            data={popularContents}
            name={"popular"}
            needFilter={false}
            linkTo={"/contents"}
            options={{ ordering: 1 }}
            moreContents={true}
          />
          <EbookList
            title={"최신 콘텐츠"}
            description={"따끈따끈 새로 올라온 콘텐츠를 만나보세요."}
            data={recentUpLoads}
            name={"recentUpLoad"}
            needFilter={false}
            linkTo={"/contents"}
            options={{ ordering: 0 }}
            moreContents={true}
          />

// 기존 하단 광고 제거, 2025-01-09, 이정우
          <BottomAD>
            <AD imgSrc={[AD_1, AD_1_m]} linkTo={"/guide"} />
            <AD imgSrc={[AD_2, AD_2_m]} linkTo={"/downLoad"} />
          </BottomAD>
*/}

          {modalVisible && (
            <PopupModal visible={modalVisible} closable={true} maskClosable={true} onClose={closeModal} data={popups}></PopupModal>
          )}
        </Container>
      </Layout>
    )}    

  /*/////////////////////////////////////////////////////////////////////
  // 2025-01-09 접속하는 기기가 모바일이 아닌 경우, 
  // - 제목 부분이 보이도록 처리 (인삿말+바로 시작하기 버튼과 아이콘 리스트 호출)
  // - 기존 이북리스트 컨텐츠 중 발견과 인기 컨텐츠를 보이지 않도록 처리
  // - 카테고리 별 컨텐츠를 각각의 요구사항에 맞춰 종, 횡 또는 종/횡으로 디스플레이 (이북리스트1~5)
  // - 기존 하단 광고 보이지 않도록 처리
  // - 최신 컨텐츠의 경우 무한 스크롤로 제공하고 그 밑에 푸터가 디스플레이 되되도록 처리
  /////////////////////////////////////////////////////////////////////*/

  else{
    return (
     <Layout>
        {isLoadingCategory1 ||
         isLoadingCategory2 ||
         isLoadingCategory3 ||
         isLoadingCategory4 ||
         isLoadingCategory5 ||
         isLoading
          ? <Loading /> : null}
        <Hashtag />
{/* 배너 부분 보이지 않도록 처리
        <Banner>
          <BannerContainer />
        </Banner>
*/}
        <Greeting>
            <div className="intro">
              나 만의 책을 <br/> 친구들과 공유 하세요
            </div>
            <div id = "btnbox1" className = "btnbox"> 
              <Link to="./contentGuide" className="btnbox"> 바로 시작하기 </Link>
            </div>
            <div id = "btnbox3" className="btnbox"> 
            </div>
            <div id = "btnbox2" className="btnbox"> 
              <Link to="./template" className="btnbox"> 템플릿 보러가기 </Link>
            </div>
        </Greeting>

        <IconList>
          <IconContainer />
        </IconList>

        <NewContainer>
          <EbookListLine1
              title={"카테고리 컨텐츠 1"}
              description={"1번 라인 종썸네일 6개"}
              data={categoryList1}
              name={"categoryContents1"}
              needFilter={false}
              linkTo={"/moreEbook"}
              moreContents={true}
          />
          <EbookListLine2
              title={"카테고리 컨텐츠 2"}
              description={"2번 라인 횡썸네일 3개"}
              data={categoryList2}
              name={"categoryContents2"}
              needFilter={false}
              linkTo={"/moreEbook"}
              moreContents={true}
          />
          <EbookListLine3
              title={"MD's Pick"}
              description={"MD's Pick 5개"}
              data={categoryList3}
              name={"categoryContents3"}
              needFilter={false}
              linkTo={"/moreEbook"}
              moreContents={true}
          />
          <EbookListLine4
              title={"테스트4"}
                description={"4번 라인 횡썸네일 4개"}
              data={categoryList4}
              name={"categoryContents4"}
              needFilter={false}
              linkTo={"/moreEbook"}
              moreContents={true}
          />
          <EbookListLine5
              title={"테스트5"}
              description={"5번 라인 횡썸네일 4개"}
              data={categoryList5}
              name={"categoryContents5"}
              needFilter={false}
              linkTo={"/moreEbook"}
              moreContents={true}
          />
          <BottomBanner>
            <Link to={"/Creator"}>
              <img src = {BottomBannerImage} width = "1058px"/>
            </Link>
          </BottomBanner>
          <EbookListRecentUpload
            title={"최신 콘텐츠"}
            description={"따끈따끈 새로 올라온 콘텐츠를 만나보세요."}
            data={recentUpLoads}
            name={"recentUpLoad"}
            needFilter={false}
            linkTo={"/contents"}
            options={{ ordering: 0 }}
            moreContents={true}
          />
          {modalVisible && (
            <PopupModal visible={modalVisible} closable={true} maskClosable={true} onClose={closeModal} data={popups}></PopupModal>
         )}
      </NewContainer>
    </Layout>
  )
};
}
