import styled from "styled-components";
import Layout from "../components/Layout";
import Container from "../components/Container";
import banner from "../images/pages/Creator/main_banner.png";
import banner_m from "../images/pages/Creator/main_banner.png";
import request from "../images/pages/Creator/creator2025033101.png";
import request_m from "../images/pages/Creator/creator2025033101.png";

import { useMediaQuery } from "react-responsive";
import Hashtag from "../components/Hashtag";

const Img = styled.img`
  display: block;
  margin-top: ${(props) => (props.tablet ? "0" : "29px")};
  width: 100%;
`;
const ButtonWrap = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 27px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  aspect-ratio: 1168/589;
  /* border: 1px solid red; */
  a {
    width: 330px;
    height: auto;
    aspect-ratio: 1/1;
    /* background-color: aqua;
    opacity: 0.3; */
    margin-bottom: 90px;
    &.phone {
      pointer-events: none;
    }
  }
`;
const MobileWrap = styled.div`
  width: 100%;
  position: relative;
  .buttonWrap {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5%;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 5%;
    aspect-ratio: 375/159;
    /* border: 1px solid red; */
    a {
      width: 100%;
      height: auto;
      aspect-ratio: 343/350;
    }
  }
`;
export default function Creator() {
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });

  return (
    <Layout>
      <Hashtag />
      {!tablet ? (
        <Container>
          <Img src={banner} alt="" />
          <Img src={request} alt="" tablet={true} />
          <ButtonWrap>
            <a
              className="map"
              href="https://map.kakao.com/?urlX=507542&urlY=1108643&urlLevel=3&itemId=2057645879&q=%ED%86%A0%EB%B8%8C%EC%86%8C%ED%94%84%ED%8A%B8&srcid=2057645879&map_type=TYPE_MAP"
              target="_blank"
            ></a>
            <a className="email" href="mailto:help@tovsoft.com"></a>
            <a className="phone" href="tel:02-6959-2716"></a>
          </ButtonWrap>
        </Container>
      ) : (
        <MobileWrap>
          <Img src={banner_m} alt="" tablet={tablet} />
          <Img src={request_m} alt="" tablet={tablet} />
          <div className="buttonWrap">
            <a
              className="map"
              href="https://map.kakao.com/?urlX=507542&urlY=1108643&urlLevel=3&itemId=2057645879&q=%ED%86%A0%EB%B8%8C%EC%86%8C%ED%94%84%ED%8A%B8&srcid=2057645879&map_type=TYPE_MAP"
              target="_blank"
            ></a>
            <a className="email" href="mailto:help@tovsoft.com"></a>
            <a className="phone" href="tel:02-6959-2716"></a>
          </div>
        </MobileWrap>
      )}
    </Layout>
  );
}
