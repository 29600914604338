import React, { useRef, useState, useEffect } from 'react';
import { useMediaQuery } from "react-responsive";

import styled from "styled-components";
import ThumbnailLine2, { ThumbnailDummy } from "./ThumbnailLine2";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Pagination, Navigation, Scrollbar, Mousewheel, Keyboard } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
};

const Wrap = styled.div`
  position: relative;
  display: grid;
  
  /* grid-template-columns: repeat(auto-fill, minmax(158px, 1fr)); */
  grid-template-columns: repeat(${(props) => (props.myRoom ? 4 : 3)}, 568px);
  justify-content: space-between;

  height: ${(props) => (props.row ? "fit-content" : "fit-content")};

  overflow: hidden;
  width: ${(props) => (props.myRoom ? "892px" : "1768px")};
  grid-row-gap: ${(props) => (props.myRoom ? "25px" : "20px")};
  margin: 0 auto;
  margin-bottom: ${(props) => (props.row ? "0" : "40px")};
  > div {
    width: 100%;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: repeat(1, 100%);
    height: fit-content;
    width: 100%;
    > div {
      width: 100%;
    }
  }
`;

export default function ThumbGridLine2({ data, row, lastElementRef, myRoom, title }) {

  const { height, width } = getWindowDimensions();
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });
  
if(tablet&&row){
    return(
    <Wrap row={row} myRoom={myRoom}>      
      {tablet && row
       ? data.slice(0, 6).map((content, i) => (
        <div key={i} ref={data.length - 1 === i ? lastElementRef : undefined}>
          <ThumbnailLine2
            bgurl={content.coverUrl}
            title={content.bookTitle}
            publisher={content.nickName}
            view={content.viewCnt}
            id={content.eSeq}
            favor={content.favorCnt}
            bookUrl={content.bookUrl}
          ></ThumbnailLine2>
        </div>
        ))
       : data.map((content, i) => (
          <div key={i} ref={data.length - 1 === i ? lastElementRef : undefined}>
            <ThumbnailLine2
              bgurl={content.coverUrl}
              title={content.bookTitle}
              publisher={content.nickName}
              view={content.viewCnt}
              id={content.eSeq}
              favor={content.favorCnt}
              bookUrl={content.bookUrl}
            ></ThumbnailLine2>
          </div>
          ))}
    </Wrap>
    );
  }
  else{
    return(
      <Swiper row={row} myRoom={myRoom}
        slidesPerView={4} 
        spaceBetween={14}
        navigation={true}
        scrollbar = {{hide : true}}
        modules={[Pagination, Navigation, Scrollbar]}
        loop={true}
      >
      {tablet && row
       ? data.slice(0, 6).map((content, i) => (
        <div key={i} ref={data.length - 1 === i ? lastElementRef : undefined}>
          <SwiperSlide>
          <ThumbnailLine2
            bgurl={content.coverUrl}
            title={content.bookTitle}
            publisher={content.nickName}
            view={content.viewCnt}
            id={content.eSeq}
            favor={content.favorCnt}
            bookUrl={content.bookUrl}
          ></ThumbnailLine2>
          </SwiperSlide>
        </div>
        ))
        : data.map((content, i) => (
            <div key={i} ref={data.length - 1 === i ? lastElementRef : undefined}>
              <SwiperSlide>
              <ThumbnailLine2
                bgurl={content.coverUrl}
                title={content.bookTitle}
                publisher={content.nickName}
                view={content.viewCnt}
                id={content.eSeq}
                favor={content.favorCnt}
                bookUrl={content.bookUrl}
              ></ThumbnailLine2>
            </SwiperSlide>
            </div>
        ))
      }
      </Swiper>  
  );
}
}
